import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
// import SwipeableViews from 'react-swipeable-views';

import Page from './Page.tsx';
import * as Common from './common';

// Explicit styles
const useStyles = makeStyles({
  tabs: {
    height: '48px',
    marginTop: '55px',
    opacity: 1
  },

  pages: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '65vh',
    marginTop: '55px'
  },

  page: {
    minHeight: '300px',
    textAlign: 'center',
    backgroundColor: '#222',
    color: 'white',
    padding: '16px 8px',
    marginBottom: '40px',
    marginLeft: '4px',
    marginRight: '4px'
  }
});

// TabPanel
type TabPanelProps = {
  tab: number,
  index: number
}
const TabPanel: React.FunctionComponent<TabPanelProps> =
  ({ tab, index, children }) =>
    {
      const classes = useStyles();
      if (tab === index)
        return <Box className={classes.page}>{children}</Box>;
      else
        return null;
    }

// Form Pages component
interface PagesProps {
  pages: Array<any>,
  fields: Common.FormFields,
  onFieldChange: (key: string, value: string) => void
}

const Pages: React.FunctionComponent<PagesProps> =
  ({ pages, fields, onFieldChange }) =>
    {
      const [tab, setTab] = React.useState(0);
      const classes = useStyles();

      return (
        <>
          <Tabs className={classes.tabs} variant="scrollable"
                scrollButtons="on" value={tab}
                onChange={(e,tab) => setTab(tab)}>
            {
              pages.map((page: any, index: number) =>
                <Tab label={page.title} key={index} />)
            }
          </Tabs>
          {/*<SwipeableViews enableMouseEvents className={classes.pages}*/}
          {/*                index = {tab}*/}
          {/*                onChangeIndex={(index) => setTab(index)}>*/}
            {
            pages.map((page:any, index: number) =>
            <TabPanel tab={tab} index={index} key={index}>
              <Page page={page} fields={fields}
                    onFieldChange={onFieldChange}
              />
            </TabPanel>
            )
          }
        {/*</SwipeableViews>*/}
      </>
    );
  };

export default Pages;
