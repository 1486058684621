import React from "react";

function MetrXLogo({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="262.835"
      height="176.627"
      version="1.1"
      viewBox="0 0 69.542 46.732"
      className={className}
    >
      <defs>
        <clipPath id="clipPath878" clipPathUnits="userSpaceOnUse">
          <path d="M442.426 363.873v-.008h-.001a10.068 10.068 0 01-9.782-7.651l-.01-.043h-.001a6.538 6.538 0 01-.07-.284l-11.619-46.621-11.614 46.619c-.023.101-.05.205-.082.326l-.001.003a10.065 10.065 0 01-9.781 7.651h-.002v.008h-39.021v-.008h-.002a10.066 10.066 0 01-9.781-7.651l-.008-.034h-.001a7.515 7.515 0 01-.073-.296s-28.195-113.589-28.195-114.392c0-5.564 4.526-10.089 10.088-10.089h26.027c4.756 0 8.891 3.358 9.87 7.999l11.586 46.507 11.586-46.507c.979-4.641 5.114-7.999 9.869-7.999h39.066c4.755 0 8.891 3.358 9.87 7.999l11.59 46.521 11.589-46.521c.979-4.642 5.115-7.999 9.87-7.999h26.027c5.563 0 10.088 4.525 10.088 10.089 0 .805-28.195 114.394-28.195 114.394-.023.098-.048.2-.08.32l-.081.326-.017.037a10.064 10.064 0 01-9.685 7.296h-.003v.008zm9.868-7.999c-.031.147-.066.293-.103.437h19.492c-.037-.144-.071-.29-.104-.437l-9.643-38.701zm-81.984 0c-.031.147-.065.293-.102.437h19.49a7.924 7.924 0 01-.102-.437l-9.643-38.709zm110.6-114.888l-.017.084-15.065 60.479 13.102 52.572.037.168a2.532 2.532 0 002.394 2.02h.086a2.524 2.524 0 002.449-1.907l27.975-112.298c.054-.22.081-.42.081-.612a2.535 2.535 0 00-2.532-2.534h-26.027a2.536 2.536 0 00-2.483 2.028m-42.957 0l-.037.167-13.081 52.487 15.141 60.75a2.523 2.523 0 002.45 1.919h.087a2.53 2.53 0 002.393-2.02l.017-.084 13.118-52.657-15.068-60.478-.017-.084a2.535 2.535 0 00-2.482-2.028h-.038a2.536 2.536 0 00-2.483 2.028m-39.027 0l-.017.084-15.063 60.467 13.099 52.584.039.168a2.529 2.529 0 002.393 2.02h.086a2.522 2.522 0 002.447-1.903l.052-.209 15.085-60.556-13.08-52.488-.038-.167a2.537 2.537 0 00-2.482-2.028h-.039a2.537 2.537 0 00-2.482 2.028m-40.974 113.251l.042.164a2.523 2.523 0 002.448 1.908h.086a2.53 2.53 0 002.394-2.02l.017-.084 13.121-52.668-15.043-60.384-.038-.167a2.535 2.535 0 00-2.482-2.028H332.47a2.537 2.537 0 00-2.533 2.534c0 .19.027.392.082.613l27.904 112.008.029.126v-.003.001m53.364-114.836l9.625 38.613h.001l9.623-38.613c.033-.149.067-.296.105-.443h-19.457c.037.147.072.294.103.443"></path>
        </clipPath>
        <linearGradient
          id="linearGradient888"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="matrix(197.12627 0 0 -197.12627 322.382 297.638)"
          gradientUnits="userSpaceOnUse"
          spreadMethod="pad"
        >
          <stop offset="0" stopColor="#0290ff" stopOpacity="1"></stop>
          <stop offset="1" stopColor="#18eca0" stopOpacity="1"></stop>
        </linearGradient>
        <clipPath id="clipPath898" clipPathUnits="userSpaceOnUse">
          <path d="M0 595.276h841.89V0H0z"></path>
        </clipPath>
        <clipPath id="clipPath906" clipPathUnits="userSpaceOnUse">
          <path d="M360.528 356.311H480.89V239.402H360.528z"></path>
        </clipPath>
        <clipPath id="clipPath910" clipPathUnits="userSpaceOnUse">
          <path d="M370.31 355.874c-.031.147-.065.293-.102.437l-9.68-.002a2.532 2.532 0 002.394-2.019l.017-.085 13.121-52.668 3.893 15.629z"></path>
        </clipPath>
        <clipPath id="clipPath914" clipPathUnits="userSpaceOnUse">
          <path d="M-32768 32767h65535v-65535h-65535z"></path>
        </clipPath>
        <linearGradient
          id="linearGradient922"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="scale(-56.90247 56.90247) rotate(76 -6.728 -1.605)"
          gradientUnits="userSpaceOnUse"
          spreadMethod="pad"
        >
          <stop offset="0" stopColor="#fff" stopOpacity="0"></stop>
          <stop offset="0.5" stopColor="#fff" stopOpacity="0"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="1"></stop>
        </linearGradient>
        <mask
          id="mask924"
          width="1"
          height="1"
          x="0"
          y="0"
          maskUnits="userSpaceOnUse"
        >
          <g>
            <g clipPath="url(#clipPath914)">
              <g>
                <g>
                  <path
                    fill="url(#linearGradient922)"
                    stroke="none"
                    d="M-32768 32767h65535v-65535h-65535z"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </mask>
        <linearGradient
          id="linearGradient942"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="scale(-56.90247 56.90247) rotate(76 -6.728 -1.605)"
          gradientUnits="userSpaceOnUse"
          spreadMethod="pad"
        >
          <stop offset="0" stopColor="#000" stopOpacity="1"></stop>
          <stop offset="0.5" stopColor="#000" stopOpacity="1"></stop>
          <stop offset="1" stopColor="#000" stopOpacity="1"></stop>
        </linearGradient>
        <clipPath id="clipPath946" clipPathUnits="userSpaceOnUse">
          <path d="M452.294 355.874a8.959 8.959 0 01-.103.437l-9.678-.002a2.53 2.53 0 002.393-2.019l.017-.085 13.121-52.668 3.893 15.629z"></path>
        </clipPath>
        <clipPath id="clipPath950" clipPathUnits="userSpaceOnUse">
          <path d="M-32768 32767h65535v-65535h-65535z"></path>
        </clipPath>
        <linearGradient
          id="linearGradient958"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="scale(-56.90247 56.90247) rotate(76 -7.448 -2.527)"
          gradientUnits="userSpaceOnUse"
          spreadMethod="pad"
        >
          <stop offset="0" stopColor="#fff" stopOpacity="0"></stop>
          <stop offset="0.5" stopColor="#fff" stopOpacity="0"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="1"></stop>
        </linearGradient>
        <mask
          id="mask960"
          width="1"
          height="1"
          x="0"
          y="0"
          maskUnits="userSpaceOnUse"
        >
          <g>
            <g clipPath="url(#clipPath950)">
              <g>
                <g>
                  <path
                    fill="url(#linearGradient958)"
                    stroke="none"
                    d="M-32768 32767h65535v-65535h-65535z"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </mask>
        <linearGradient
          id="linearGradient978"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="scale(-56.90247 56.90247) rotate(76 -7.448 -2.527)"
          gradientUnits="userSpaceOnUse"
          spreadMethod="pad"
        >
          <stop offset="0" stopColor="#000" stopOpacity="1"></stop>
          <stop offset="0.5" stopColor="#000" stopOpacity="1"></stop>
          <stop offset="1" stopColor="#000" stopOpacity="1"></stop>
        </linearGradient>
        <clipPath id="clipPath982" clipPathUnits="userSpaceOnUse">
          <path d="M398.909 241.07l-15.063 60.467-3.893-15.628 11.586-46.507z"></path>
        </clipPath>
        <clipPath id="clipPath986" clipPathUnits="userSpaceOnUse">
          <path d="M-32768 32767h65535v-65535h-65535z"></path>
        </clipPath>
        <linearGradient
          id="linearGradient994"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="scale(-62.3154 62.3154) rotate(76 -5.638 -2.132)"
          gradientUnits="userSpaceOnUse"
          spreadMethod="pad"
        >
          <stop offset="0" stopColor="#fff" stopOpacity="0"></stop>
          <stop offset="0.3" stopColor="#fff" stopOpacity="0"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="1"></stop>
        </linearGradient>
        <mask
          id="mask996"
          width="1"
          height="1"
          x="0"
          y="0"
          maskUnits="userSpaceOnUse"
        >
          <g>
            <g clipPath="url(#clipPath986)">
              <g>
                <g>
                  <path
                    fill="url(#linearGradient994)"
                    stroke="none"
                    d="M-32768 32767h65535v-65535h-65535z"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </mask>
        <linearGradient
          id="linearGradient1014"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="scale(-62.3154 62.3154) rotate(76 -5.638 -2.132)"
          gradientUnits="userSpaceOnUse"
          spreadMethod="pad"
        >
          <stop offset="0" stopColor="#000" stopOpacity="1"></stop>
          <stop offset="0.3" stopColor="#000" stopOpacity="1"></stop>
          <stop offset="1" stopColor="#000" stopOpacity="1"></stop>
        </linearGradient>
        <clipPath id="clipPath1018" clipPathUnits="userSpaceOnUse">
          <path d="M480.891 241.081l-15.064 60.468-3.893-15.629 11.586-46.507z"></path>
        </clipPath>
        <clipPath id="clipPath1022" clipPathUnits="userSpaceOnUse">
          <path d="M-32768 32767h65535v-65535h-65535z"></path>
        </clipPath>
        <linearGradient
          id="linearGradient1030"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="scale(-62.3154 62.3154) rotate(76 -6.296 -2.974)"
          gradientUnits="userSpaceOnUse"
          spreadMethod="pad"
        >
          <stop offset="0" stopColor="#fff" stopOpacity="0"></stop>
          <stop offset="0.3" stopColor="#fff" stopOpacity="0"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="1"></stop>
        </linearGradient>
        <mask
          id="mask1032"
          width="1"
          height="1"
          x="0"
          y="0"
          maskUnits="userSpaceOnUse"
        >
          <g>
            <g clipPath="url(#clipPath1022)">
              <g>
                <g>
                  <path
                    fill="url(#linearGradient1030)"
                    stroke="none"
                    d="M-32768 32767h65535v-65535h-65535z"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </mask>
        <linearGradient
          id="linearGradient1050"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="scale(-62.3154 62.3154) rotate(76 -6.296 -2.974)"
          gradientUnits="userSpaceOnUse"
          spreadMethod="pad"
        >
          <stop offset="0" stopColor="#000" stopOpacity="1"></stop>
          <stop offset="0.3" stopColor="#000" stopOpacity="1"></stop>
          <stop offset="1" stopColor="#000" stopOpacity="1"></stop>
        </linearGradient>
      </defs>
      <g transform="translate(81.64 -128.49) matrix(.35278 0 0 -.35278 -195.37 256.857)">
        <g>
          <g clipPath="url(#clipPath878)">
            <g>
              <g>
                <path
                  fill="url(#linearGradient888)"
                  stroke="none"
                  d="M442.426 363.873v-.008h-.001a10.068 10.068 0 01-9.782-7.651l-.01-.043h-.001a6.538 6.538 0 01-.07-.284l-11.619-46.621-11.614 46.619c-.023.101-.05.205-.082.326l-.001.003a10.065 10.065 0 01-9.781 7.651h-.002v.008h-39.021v-.008h-.002a10.066 10.066 0 01-9.781-7.651l-.008-.034h-.001a7.515 7.515 0 01-.073-.296s-28.195-113.589-28.195-114.392c0-5.564 4.526-10.089 10.088-10.089h26.027c4.756 0 8.891 3.358 9.87 7.999l11.586 46.507 11.586-46.507c.979-4.641 5.114-7.999 9.869-7.999h39.066c4.755 0 8.891 3.358 9.87 7.999l11.59 46.521 11.589-46.521c.979-4.642 5.115-7.999 9.87-7.999h26.027c5.563 0 10.088 4.525 10.088 10.089 0 .805-28.195 114.394-28.195 114.394-.023.098-.048.2-.08.32l-.081.326-.017.037a10.064 10.064 0 01-9.685 7.296h-.003v.008zm9.868-7.999c-.031.147-.066.293-.103.437h19.492c-.037-.144-.071-.29-.104-.437l-9.643-38.701zm-81.984 0c-.031.147-.065.293-.102.437h19.49a7.924 7.924 0 01-.102-.437l-9.643-38.709zm110.6-114.888l-.017.084-15.065 60.479 13.102 52.572.037.168a2.532 2.532 0 002.394 2.02h.086a2.524 2.524 0 002.449-1.907l27.975-112.298c.054-.22.081-.42.081-.612a2.535 2.535 0 00-2.532-2.534h-26.027a2.536 2.536 0 00-2.483 2.028m-42.957 0l-.037.167-13.081 52.487 15.141 60.75a2.523 2.523 0 002.45 1.919h.087a2.53 2.53 0 002.393-2.02l.017-.084 13.118-52.657-15.068-60.478-.017-.084a2.535 2.535 0 00-2.482-2.028h-.038a2.536 2.536 0 00-2.483 2.028m-39.027 0l-.017.084-15.063 60.467 13.099 52.584.039.168a2.529 2.529 0 002.393 2.02h.086a2.522 2.522 0 002.447-1.903l.052-.209 15.085-60.556-13.08-52.488-.038-.167a2.537 2.537 0 00-2.482-2.028h-.039a2.537 2.537 0 00-2.482 2.028m-40.974 113.251l.042.164a2.523 2.523 0 002.448 1.908h.086a2.53 2.53 0 002.394-2.02l.017-.084 13.121-52.668-15.043-60.384-.038-.167a2.535 2.535 0 00-2.482-2.028H332.47a2.537 2.537 0 00-2.533 2.534c0 .19.027.392.082.613l27.904 112.008.029.126v-.003.001m53.364-114.836l9.625 38.613h.001l9.623-38.613c.033-.149.067-.296.105-.443h-19.457c.037.147.072.294.103.443"
                ></path>
              </g>
            </g>
          </g>
        </g>
        <g>
          <g clipPath="url(#clipPath898)">
            <g>
              <g>
                <g clipPath="url(#clipPath906)" opacity="0.5">
                  <g>
                    <g clipPath="url(#clipPath910)">
                      <g>
                        <g mask="url(#mask924)">
                          <g>
                            <path
                              fill="url(#linearGradient942)"
                              stroke="none"
                              d="M370.31 355.874c-.031.147-.065.293-.102.437l-9.68-.002a2.532 2.532 0 002.394-2.019l.017-.085 13.121-52.668 3.893 15.629z"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g clipPath="url(#clipPath946)">
                      <g>
                        <g mask="url(#mask960)">
                          <g>
                            <path
                              fill="url(#linearGradient978)"
                              stroke="none"
                              d="M452.294 355.874a8.959 8.959 0 01-.103.437l-9.678-.002a2.53 2.53 0 002.393-2.019l.017-.085 13.121-52.668 3.893 15.629z"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g clipPath="url(#clipPath982)">
                      <g>
                        <g mask="url(#mask996)">
                          <g>
                            <path
                              fill="url(#linearGradient1014)"
                              stroke="none"
                              d="M398.909 241.07l-15.063 60.467-3.893-15.628 11.586-46.507z"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g clipPath="url(#clipPath1018)">
                      <g>
                        <g mask="url(#mask1032)">
                          <g>
                            <path
                              fill="url(#linearGradient1050)"
                              stroke="none"
                              d="M480.891 241.081l-15.064 60.468-3.893-15.629 11.586-46.507z"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <g transform="translate(515.4 353.832)">
              <path
                fill="#14dcb0"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c-.388-2.282-2.1-3.628-4.13-3.628-2.898 0-4.815 2.259-4.815 5.157 0 2.944 1.802 5.157 4.747 5.157 2.098 0 3.81-1.255 4.13-3.537h-1.849c-.228 1.05-1.141 1.757-2.327 1.757-1.803 0-2.784-1.437-2.784-3.377 0-1.871 1.095-3.377 2.875-3.377 1.164 0 2.077.661 2.236 1.848zm-4.45 8.261c-3.719 0-6.64-2.898-6.64-6.778 0-3.879 2.921-6.777 6.64-6.777 3.675 0 6.64 2.898 6.64 6.777 0 3.88-2.965 6.778-6.64 6.778m0 1.78c4.518 0 8.557-3.446 8.557-8.558 0-5.111-4.039-8.557-8.557-8.557-4.518 0-8.557 3.446-8.557 8.557 0 5.112 4.039 8.558 8.557 8.558"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default MetrXLogo;
