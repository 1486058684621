import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// Explicit styles
const useStyles = makeStyles({
  control: {
    margin: '10px 0',
    border: "solid 1px rgba(255, 255, 255, 0.23)",
    borderRadius: "4px",
    padding: "1em",
    minWidth: '70vw'
  },
  label: {
    textAlign: 'left',
    backgroundColor: '#222',
    padding: '0 2px'
  }
});

// Dropdown selector component
interface CCheckboxProps {
  component: any,
  value: string,
  onChange: (value: string) => void
}

const CCheckbox: React.FunctionComponent<CCheckboxProps> =
  ({ component, value, onChange }) =>
    {
      const classes = useStyles();

      return (
        <FormGroup variant="outlined" className={classes.control}>
        <FormControlLabel label={component.label} className={classes.label}
        control={<Checkbox checked={value==="YES"}
                           name={component.id}
                           onChange={e => onChange(e.target.checked?"YES":"NO")}/>}
        />
        </FormGroup>
      );
    };

export default CCheckbox;
