import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CameraIcon from '@material-ui/icons/Camera';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import ReplayIcon from '@material-ui/icons/Replay';
import Webcam from 'react-webcam';

// Explicit styles
const useStyles = makeStyles({
  camera: {
    margin: '20px 0',
    width: '280px',
    height: '280px'
  }
});

const videoConstraints = {
  width: 640,
  height: 640,
  facingMode: "environment"
};

// Photo component
interface CPhotoProps {
  component: any,
  value: string,
  onChange: (value: string) => void
}

const CPhoto: React.FunctionComponent<CPhotoProps> =
  ({ component, value, onChange }) =>
    {
      const classes = useStyles();
      const webcamRef = React.useRef<Webcam>(null);
      const fileInputRef = React.useRef<HTMLInputElement>(null);

      // Capture from camera
      const capture = () => {
        if (webcamRef.current)
        {
          const image = webcamRef.current.getScreenshot();
          if (image) onChange(image);
        }
      };

      // Trigger upload from the hidden <input type='file'>
      const startUpload = () => {
        if (fileInputRef.current)
          fileInputRef.current.click();
      };

      // Upload file after selection
      const upload = (e: any) => {
        if (e.target.files.length)
        {
          const file = e.target.files[0];
          const url = URL.createObjectURL(file);

          // Fetch it and encode as data URL
          fetch(url)
            .then(res => res.blob())
            .then(blob =>
              {
                const fr = new FileReader();
                fr.onload = (e: any) => onChange(e.target.result);
                fr.readAsDataURL(blob);
                URL.revokeObjectURL(url);
              });
        }
      };

      if (value)
      {
        // Already taken
        return (
          <>
            <img className={classes.camera} src={value} alt="" />
            <IconButton size="medium" aria-label="retake picture"
                        onClick={() => onChange("")}>
              <ReplayIcon />
            </IconButton>
          </>
        );
      }
      else
      {
        return (
          <>
            <Webcam className={classes.camera}
                    audio={false} ref={webcamRef} screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
            />
            <IconButton size="medium" aria-label="take a picture"
                        onClick={capture}>
              <CameraIcon />
            </IconButton>
            <input type="file" style={{ display: 'none' }}
                   accept="image/*"
                   ref={fileInputRef} onChange={upload} />
            <IconButton size="medium" aria-label="upload a picture"
                        onClick={startUpload}>
              <PhotoLibraryIcon />
            </IconButton>
          </>
        );
      }
  };

export default CPhoto;
