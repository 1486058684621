import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

// Explicit styles
const useStyles = makeStyles({
  image: {
    margin: '20px 0',
    width: '280px',
  }
});

// Image component
interface CImageProps {
  component: any
}

const CImage: React.FunctionComponent<CImageProps> =
  ({ component }) =>
{
  const classes = useStyles();

  return (
    <img className={classes.image} src={component.url} alt="" />
  );
}

export default CImage;
