import React from 'react';
import ReactPlayer from 'react-player';
import { makeStyles } from '@material-ui/core/styles';

// Explicit styles
const useStyles = makeStyles({
  video: {
    margin: 'auto',
    width: '280px',
    height: 'calc(280x * 9 / 16)'
  }
});

// Video component
interface CVideoProps {
  component: any
}

const CVideo: React.FunctionComponent<CVideoProps> =
  ({ component }) =>
{
  const classes = useStyles();

  return (
    <div className={classes.video}>
      <ReactPlayer url={component.url} width="100%" height="100%" />
    </div>
  );
}

export default CVideo;
