import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SignaturePad from 'react-signature-canvas';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

// Explicit styles
const useStyles = makeStyles({
  sigPad: {
    width: "80vw",
    height: "80vw",
    backgroundColor: "#ccc"
  }
});

// Signature component
interface CSignatureProps {
  component: any,
  value: string,
  onChange: (value: string) => void
}

const CSignature: React.FunctionComponent<CSignatureProps> =
  ({ component, value, onChange }) =>
    {
      const classes = useStyles();
      const sigRef = React.useRef<SignaturePad>(null);

      // Set initial value
      useEffect( () => {
        if (sigRef.current) sigRef.current.fromDataURL(value);
      },[value]);

      // Clear the canvas
      const clear = () => {
        if (sigRef.current) sigRef.current.clear();
        onChange("");
      };

      // Capture the canvas
      const capture = () => {
        if (sigRef.current) {
          let data = sigRef.current.toDataURL("image/png");
          onChange(data);
        }
      };

      return (
        <>
          <SignaturePad canvasProps={{className: classes.sigPad}}
                        ref={sigRef} onEnd={capture} 
                        clearOnResize={false}/>
          <br/>
          <IconButton size="medium" aria-label="clear signature"
                      onClick={clear}>
            <ClearIcon />
          </IconButton>
          <br/>
        </>
      );
  };

export default CSignature;
