import React, { useEffect } from 'react';
import { createTheme, ThemeProvider, makeStyles }
  from '@material-ui/core/styles';

import Login from './Login.tsx';
import Form from './Form.tsx';
import Forms from './Forms.tsx';

import config from './config.json';
import MetrXLogo from './graphics/MetrxLogo.tsx';
import Cookie from 'js-cookie'

// Theme overrides
const theme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: "#0392fd"
    },
    secondary: {
      main: "#18eaa2"
    }
  }
});

// Explicit styles
const useStyles = makeStyles({
  app: {
    width: '100vw'
  },

  header: {
    position: 'fixed',
    top: '0px',
    height: '40px',
    width: 'calc(100% - 20px)',
    padding: '10px',
    borderBottom: 'solid 1px #444',
    opacity: 1.0,
    zIndex: 100
  },

  logo: {
    height: '40px',
    float: 'left',
    marginLeft: '-80px'
  },

  wordmark: {
    display: 'inline-block',
    float: 'right',
    height: '24px',
    marginTop: '8px'
  },

  alert: {
    marginTop: '5rem',
    color: 'black',
    border: 'solid 2px red',
    padding: '1rem',
    backgroundColor: 'red',
    width: '30%',
    margin: 'auto',
    textAlign: 'center',
    cursor: 'pointer'
  }
});

// Main app
const App: React.FunctionComponent = () =>
  {
    const [authTokens, setAuthTokens] = React.useState<any>(null);
    const [forms, setForms] = React.useState<any>(null);
    const [form, setForm] = React.useState<string | null>(null);
    const [errors, setErrors] = React.useState<any>("");
    const classes = useStyles();
 
    const handleErrors = (response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response;
    }

    // Load forms from API
    useEffect( () => {
      if (authTokens) {
        Cookie.set('metrx', authTokens.access_token, { expires: 0.5 });
      } 

      if (Cookie.get('metrx')) {
        fetch(config.formsURL,
          {
            headers: {
              'Authorization': 'Bearer '+Cookie.get('metrx')
            }
          })
        .then(handleErrors)
        .then((response) => response.json())
        .then(json => {
          setForms(json) 
        })
        .catch((error) => {
          console.log('Errors !')
          setErrors(error)
          console.log(error)
        })
      }

    }, [authTokens]);

    const removeCookie = () => {
      Cookie.remove('metrx');
      console.log('removing cookie')
    }

    if (errors) {
      return (
        <>
          <ThemeProvider theme={theme}>
          <div className={classes.app}>
            <header className={classes.header}>
                <MetrXLogo className={classes.logo}/>
            </header>
          </div>
          </ThemeProvider>
          <div onClick={removeCookie} className={classes.alert}>{ errors }</div>
        </>
      )
    }

    if(Cookie.get('metrx')) {
        return (
          <ThemeProvider theme={theme}>
            <div className={classes.app}>
              <header className={classes.header}>
                  <MetrXLogo className={classes.logo}/>
              </header>
              {
                !forms
                ?
                  <div>Loading</div>
                :
                (
                  form?
                  <Form id={form} tokens={authTokens}
                        onExit={ () => setForm(null) }/>
                  : (forms && <Forms forms={forms}
                                    onFormChange={ (id) => setForm(id) }/>)
                )
              }
            </div>
          </ThemeProvider>
        );
      };
    
    if(!Cookie.get('metrx')) {
      return (
        <ThemeProvider theme={theme}>
          <div className={classes.app}>
            <header className={classes.header}>
                <MetrXLogo className={classes.logo}/>
            </header>
                <Login setTokens={ (tokens) => setAuthTokens(tokens) } />
          </div>
        </ThemeProvider>
      );
    };
  }

export default App;
