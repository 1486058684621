import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DateTimePicker, DatePicker } from '@material-ui/pickers';

// Explicit styles
const useStyles = makeStyles({
  date: {
    margin: '20px 0',
    minWidth: '70vw',
    '& input': {
      textAlign: 'center'
    }
  }
});

// Text field component
interface CDateProps {
  component: any,
  value: string,
  onChange: (value: string) => void
}

const CDate: React.FunctionComponent<CDateProps> =
  ({ component, value, onChange }) =>
    {
      const classes = useStyles();

      return (
        <DatePicker id={component.id} className={classes.date}
                        value={value?value:null} inputVariant="outlined"
                        label={component.label} required
                        format="dd/MM/yyyy"
                        onChange={date => onChange(date?date.toISOString():"")}
        />
      );
  };

export default CDate;
