import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

// Explicit styles
const useStyles = makeStyles({
  control: {
    margin: '5px 0',
//    border: "solid 1px rgba(255, 255, 255, 0.23)",
    borderRadius: "4px",
    padding: "4px",
    minWidth: '70vw'
  },
  label: {
    textAlign: 'left',
    backgroundColor: '#222',
    padding: '0 2px'
  },
  badge: {
    color: "#0392fd",
    backgroundColor: '#666',
    borderRadius: "4px",
    padding: '4px 6px 2px 4px'
  },
  slider: {
    margin: "0 auto auto auto",
    width: '90%',
    '& .MuiSlider-markLabel': {
      color: 'white'
    }
  }
});

// Slider field component
interface CSelectorProps {
  component: any,
  value: string,
  onChange: (value: string) => void
}

const CSelector: React.FunctionComponent<CSelectorProps> =
  ({ component, value, onChange }) =>
    {
      const classes = useStyles();

      let min:number|undefined;
      let max:number|undefined;
      for(let opt of component.values)
      {
        let v:number = +opt.value;
        if (min === undefined || v < min) min=v;
        if (max === undefined || v > max) max=v;
      }
      return (
        <FormControl className={classes.control} size="small"
                     component="fieldset" variant="standard">
          <FormLabel className={classes.label}
                     component="legend">{component.label}</FormLabel>
          <Slider id={component.id} className={classes.slider}
                  color="secondary"
                  min={min} max={max} step={1}
                  marks = {
                    component.values.map((opt: any) =>
                      { return { value: opt.value,
                                 label: opt.name }})
                  }
                  value={+value}
                  onChangeCommitted = {(e, value) => onChange(value.toString())}
          />
        </FormControl>
      );
    };

export default CSelector;
