import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

// Explicit styles
const useStyles = makeStyles({
  text: {
    margin: '10px 0px 40px 0px',
    minWidth: '70vw'
  }
});

// Text field component
interface CTextProps {
  component: any,
  value: string,
  onChange: (value: string) => void
}

const CText: React.FunctionComponent<CTextProps> =
  ({ component, value, onChange }) =>
    {
      const classes = useStyles();
      const multi = true;

      return (
        <TextField id={component.id} className={classes.text} required={component.required} size="small"
                   variant="standard" multiline={component.multi ? component.multi : false} rows={component.rows ? component.rows : 1} rowsMax="10" value={value} label={component.label}
                   onChange={e => onChange(e.target.value)}/>
      );
  };

export default CText;
