import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// Explicit styles
const useStyles = makeStyles({
  control: {
    margin: '20px 0',
    border: "solid 1px rgba(255, 255, 255, 0.23)",
    borderRadius: "4px",
    padding: "1em"
  },
  label: {
    textAlign: 'left',
    backgroundColor: '#222',
    padding: '0 2px'
  },
  group: {
    minWidth: '70vw',
  }
});

// Dropdown selector component
interface CRadioProps {
  component: any,
  value: string,
  onChange: (value: string) => void
}

const CRadio: React.FunctionComponent<CRadioProps> =
  ({ component, value, onChange }) =>
    {
      const classes = useStyles();

      return (
        <FormControl className={classes.control}
                     component="fieldset" variant="outlined">
          <FormLabel className={classes.label}
                     component="legend">{component.label}</FormLabel>
          <RadioGroup id={component.id} className={classes.group}
                      value={value || ""}  // force controlled
                      onChange={(e:any) => onChange(e.target.value)}>
            {
              component.values.map((opt: any, index: number) =>
                <FormControlLabel key={index} value={opt.value}
                                  control ={ <Radio/> } label={opt.name}/>
              )
            }
          </RadioGroup>
        </FormControl>
      );
    };

export default CRadio;
