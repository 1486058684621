// An individual form
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Cookie from 'js-cookie'

import Pages from './Pages.tsx';
import * as Common from './common';

import config from './config.json';

// Explicit styles
const useStyles = makeStyles({
  footer: {
    position: 'fixed',
    left: '0px',
    bottom: '0px',
    height: '40px',
    width: 'calc(100% - 40px)',
    padding: '10px 20px',
    borderTop: 'solid 1px #444',
    backgroundColor: '#111',
    opacity: 0.95
  },

  cancel: {
    padding: '6px'
  },

  submit: {
    float: 'right'
  },

  confirm: {
    float: 'right',
    color: "#18eaa2",
    margin: '8px'
  },

  error: {
    float: 'right',
    color: "red",
    margin: '8px'
  },

  progress:
  {
    position: "absolute",
    top: "8px",
    left: "calc(50vw - 25px)"
  }
});

let errorMessage = 'Sending Failed'

// Form component
interface FormProps {
  id: string;
  tokens: any;
  onExit: () => void
}

const Form: React.FunctionComponent<FormProps> =
  ({ id, tokens, onExit }) =>
    {
      const [form, setForm] = React.useState<any>(null);
      const [fields, setFields] = React.useState<Common.FormFields>({});
      const [showSubmitting, setShowSubmitting ] = React.useState(false);
      const [showSuccess, setShowSuccess ] = React.useState(false);
      const [showError, setShowError ] = React.useState(false);
      const [showSubmitButton, setShowSubmitButton ] = React.useState(true);
      const classes = useStyles();

      // Load form from API
      useEffect( () => {
        fetch(config.formURLPrefix+id,
              {
                headers: {
                  'Authorization': 'Bearer '+ Cookie.get('metrx')
                }
              })
          .then(response => response.json())
          .then(json => setForm(json))
          .catch((error) => {
            console.error('Form fetch error: ', error);
          });
      }, [id, tokens]);

      function onFieldChange(key: string, value: string) {
        setFields({...fields, [key]: value});
      }

      function cancel()
      {
        onExit();
      }

      function FormValidated()
      {
        let formValid = true
        let requiredFields = 0

        if (Object.keys(fields).length === 0) { return false }

        form.pages.forEach((page: any) => {
          page.components.forEach((component: any) => {
            if (component.required === true) {
              requiredFields++

              // If component id does not exists in the fields then form is incomplete
              if (!Object.keys(fields).includes(component.id)) {
                formValid = false
              }
            }
          })
        })

        // If we reach here, then all required fields are complete
        return formValid
      }

      function submit()
      {
         form['responses'] = fields

         if (!FormValidated()) {
          errorMessage = "Form Incomplete"
          setShowError(true)
          return
        }

        // Prevent submission if no fields are filled in
        setShowSubmitting(true);
        fetch(config.formPostPrefix+id,
            {
              method: 'POST',
              body: JSON.stringify(form),
              headers: {
                'Authorization': 'Bearer '+Cookie.get('metrx')
              }
            })
            .then(response => {
              setShowSubmitting(false);
              setShowSuccess(true);
            })
            .catch((error) => {
              console.error('POST error: ', error);
              errorMessage = 'Network Error'
              setShowSubmitting(false);
              setShowError(true);
            });
      }

      return (
      <>
        { form && <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Pages pages={form.pages} fields={fields}
                   onFieldChange={onFieldChange} />
          </MuiPickersUtilsProvider>
        }
        <footer className={classes.footer}>
          <IconButton className={classes.cancel} size="medium"
                      aria-label="cancel"
                      onClick={cancel}>
            <CancelIcon />
          </IconButton>
          {
            showSubmitButton && (showSuccess?
                     <>
                       <Button className={classes.submit }
                               variant="contained" color="secondary"
                               onClick = {onExit}>
                         OK
                       </Button>
                       <p className={classes.confirm}>Report sent</p>
                     </>
                  :
                     <>
                       <Button className={classes.submit }
                               disabled={showSubmitting}
                               variant="contained" color="primary"
                               onClick = {submit}>
                         Submit
                       </Button>
                      { showError &&
                         <p className={classes.error}>{errorMessage}</p> }
                     </>
                   )
          }
          {
            showSubmitting && <CircularProgress className={classes.progress} />
          }
        </footer>
      </>
      );
  };

export default Form;
