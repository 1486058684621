import React from 'react';
import CText from './CText.tsx';
import CDate from './CDate.tsx';
import CDatetime from "./CDatetime.tsx";
import CDropdown from './CDropdown.tsx';
import CSelector from './CSelector.tsx';
import CRadio from './CRadio.tsx';
import CCheckbox from './CCheckbox.tsx';
import CPhoto from './CPhoto.tsx';
import CSignature from './CSignature.tsx';
import CImage from './CImage.tsx';
import CStatic from './CStatic.tsx';
import CVideo from './CVideo.tsx';
import * as Common from './common';

// Definition of component factory array
interface ComponentFactory
{
  [index: string]: JSX.Element;
}

// Form Page component
interface PageProps {
  page: any,
  fields: Common.FormFields,
  onFieldChange: (key: string, value: string) => void
}

const Page: React.FunctionComponent<PageProps> =
  ({ page, fields, onFieldChange }) =>
    {
      return (
        <>
          {
            page.components.map((c: any, index: number) =>
              ({
                "text":
                <CText component={c} value={fields[c.id]} key={index}
                       onChange={(value) => onFieldChange(c.id, value)} />,
                "dropdown":
                <CDropdown component={c} value={fields[c.id]} key={index}
                           onChange={(value) => onFieldChange(c.id, value)} />,
                "radio":
                <CRadio component={c} value={fields[c.id]} key={index}
                        onChange={(value) => onFieldChange(c.id, value)} />,
                "checkbox":
                <CCheckbox component={c} value={fields[c.id]} key={index}
                           onChange={(value) => onFieldChange(c.id, value)} />,
                "selector":
                <CSelector component={c} value={fields[c.id]} key={index}
                           onChange={(value) => onFieldChange(c.id, value)} />,
                "date":
                <CDate component={c} value={fields[c.id]} key={index}
                       onChange={(value) => onFieldChange(c.id, value)} />,
                "datetime":
                <CDatetime component={c} value={fields[c.id]} key={index}
                       onChange={(value) => onFieldChange(c.id, value)} />,
                "photo":
                <CPhoto component={c} value={fields[c.id]} key={index}
                        onChange={(value) => onFieldChange(c.id, value)} />,
                "signature":
                <CSignature component={c} value={fields[c.id]} key={index}
                            onChange={(value) => onFieldChange(c.id, value)} />,

                // Static media
                "image":  <CImage component={c}/>,
                "static": <CStatic component={c}/>,
                "video":  <CVideo component={c}/>
              } as ComponentFactory)[c.type]
            )
          }
        </>
      );
  };

export default Page;
