// Login page
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField';
import config from './config.json';
// new
import Cookies from 'js-cookie';

// Explicit styles
const useStyles = makeStyles({
  loginForm: {
    padding: '10px',
    width: '90vw',
    marginTop: '40px'
  },
  field: {
    width: '90%',
    margin: '10px'
  },
  button: {
    float: 'right',
    display: 'block',
    margin: '10px 20px'
  },
  error: {
    color: 'red',
    marginLeft: '10px'
  }
});

// Login component
interface LoginProps {
  setTokens: (tokens: any) => void
}

const Login: React.FunctionComponent<LoginProps> =
  ({ setTokens }) =>
    {
      const [ username, setUsername ] = useState<string>(config.defaultUsername ?? "");
      const [ password, setPassword ] = useState<string>(config.defaultPassword ?? "");
      const [ showError, setShowError ] = useState(false);

      const classes = useStyles();

      function login()
      {
        const request: any = {
          email: username,
          password: password
        };

        fetch(config.loginURL,
              {
                method: "POST",
                body: JSON.stringify(request)
              }
        )
        .then(response => { if (response.ok) { return response.json() } else { throw new Error("Unauthorized")}})
        .then(json => {
          setTokens(json);
        })
        .catch((error) => {
          console.error('Login error: ', error);
          setShowError(true);
        })
      }

      return (
        <Container className={classes.loginForm}>
          <h2>Please log in with your HCM email and password.</h2>
          <TextField className={classes.field}
                     variant="outlined" value={username} label="Email"
                     onChange={e => setUsername(e.target.value)}/>
          <TextField className={classes.field}
                     type="password"
                     variant="outlined" value={password} label="Password"
                     onChange={e => setPassword(e.target.value)}/>
          <Button className={classes.button}
                  variant="contained" color="primary"
                  onClick = {login}>
            Login
          </Button>
          { showError && <p className={classes.error}>Please try again</p> }
        </Container>
      );
  };

export default Login;
