import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import AssignmentIcon from '@material-ui/icons/Assignment';

// Explicit styles
const useStyles = makeStyles( (theme) => ({
  list: {
    height: '85vh',
    overflow: 'auto',
    marginTop: '50px'
  },

  avatar: {
    backgroundColor: theme.palette.primary.main
  }
}));

// Forms component
interface FormsProps {
  forms: Array<any>,
  onFormChange: (form: string) => void
}

const Forms: React.FunctionComponent<FormsProps> =
  ({ forms, onFormChange }) =>
    {
      const classes = useStyles();

      return (
        <List className={classes.list}>
          {
          forms.map((form: any, index: number) =>
          <div key={form.id}>
            { index!==0 && <Divider variant="inset" component="li" /> }
            <ListItem alignItems="flex-start"
                      onClick={ (e) => onFormChange(form.id) }>
              <ListItemAvatar>
                <Avatar className={classes.avatar}
                        alt="" variant="rounded" src={form.icon}>
                  { form.icon || <IconButton size="medium">
                                   <AssignmentIcon />
                                 </IconButton>
                               }
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={form.name}
                            secondary={form.description}/>
            </ListItem>
          </div>
          )
        }
        </List>
      );
    };

export default Forms;
