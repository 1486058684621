import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

// Explicit styles
const useStyles = makeStyles({
  control: {
    margin: '20px 0',
  },
  select: {
    minWidth: '70vw'
  }
});

// Dropdown selector component
interface CDropdownProps {
  component: any,
  value: string,
  onChange: (value: string) => void
}

const CDropdown: React.FunctionComponent<CDropdownProps> =
  ({ component, value, onChange }) =>
    {
      const classes = useStyles();

      return (
        <FormControl className={classes.control} variant="outlined" size="small">
          <InputLabel>{component.label}</InputLabel>
          <Select id={component.id} className={classes.select}
                  value={value}
                  label={component.label}
                  native
                  onChange={(e:any) => onChange(e.target.value)}>
            <option aria-label="None" value="" />
            {
              component.values.map((opt: any, index: number) =>
                <option
                    key={index}
                    value={opt.value}
                    selected={ component.default == opt.value}
                >
                    {opt.name}
                </option>
              )
            }
          </Select>
        </FormControl>
      );
    };

export default CDropdown;
