import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DateTimePicker } from '@material-ui/pickers';

// Explicit styles
const useStyles = makeStyles({
    date: {
        margin: '20px 0',
        minWidth: '70vw',
        '& input': {
            textAlign: 'center'
        }
    }
});

// Text field component
interface CDatetimeProps {
    component: any,
    value: string,
    onChange: (value: string) => void
}

const CDatetime: React.FunctionComponent<CDatetimeProps> =
    ({ component, value, onChange }) =>
    {
        const classes = useStyles();

        return (
            <DateTimePicker id={component.id} className={classes.date}
                        value={value?value:null} inputVariant="outlined"
                        label={component.label} required
                        ampm={false} format="dd/MM/yyyy HH:mm"
                        onChange={date => onChange(date?date.toISOString():"")}
            />
        );
    };

export default CDatetime;
