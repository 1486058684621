import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

// Explicit styles
const useStyles = makeStyles({
  heading: {
  },

  description: {
  }
});

// Static component
interface CStaticProps {
  component: any
}

const CStatic: React.FunctionComponent<CStaticProps> =
  ({ component }) =>
{
  const classes = useStyles();

  return (
    <>
      <h3 className={classes.heading} dangerouslySetInnerHTML={{ __html: component.label}} ></h3>
      <p className={classes.description} dangerouslySetInnerHTML={{ __html: component.description}}></p>
    </>
  );
}

export default CStatic;
